<template>
  <div>
    <v-row>
      <v-col cols="12" md="3">
        <v-card rounded="xl" flat outlined>
          <v-card-subtitle class="EoleYellow--text text-center font-weight-bold"
            >{{ currentMonth }} {{ currentYear }}</v-card-subtitle
          >
          <v-card-text class="text-center pa-5 mt-n5">
            <i class="fa-solid fa-signature fa-4x EoleBlue--text"></i>
            <p class="mt-5">12 DUE signées</p>

            <v-row class="mt-n8 mb-n8">
              <v-col cols="12" md="12">
                <v-card-subtitle
                  class="text-center text-h5 EoleBlue--text font-weight-bold"
                  style="font-size: 0.9rem !important"
                  >600€ de CA
                </v-card-subtitle>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="3">
        <v-card rounded="xl" flat outlined>
          <v-card-subtitle class="EoleYellow--text text-center font-weight-bold"
            >POUR L'ANNÉE {{ currentYear }}</v-card-subtitle
          >
          <v-card-text class="text-center pa-5 mt-n5">
            <i class="fa-solid fa-signature fa-4x EoleBlue--text"></i>
            <p class="mt-5">24 DUE signées</p>

            <v-row class="mt-n8 mb-n8">
              <v-col cols="12" md="12">
                <v-card-subtitle
                  class="text-center text-h5 EoleBlue--text font-weight-bold"
                  style="font-size: 0.9rem !important"
                  >1200€ de CA
                </v-card-subtitle>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="3">
        <v-card rounded="xl" flat outlined>
          <v-card-subtitle class="EoleYellow--text text-center font-weight-bold"
            >{{ currentMonth }} {{ currentYear }}</v-card-subtitle
          >
          <v-card-text class="text-center pa-5 mt-n5">
            <i class="fa-solid fa-magnifying-glass-chart fa-4x EoleBlue--text"></i>
            <p class="mt-5">12 DUE initiées</p>

            <v-row class="mt-n8 mb-n8">
              <v-col cols="12" md="12">
                <v-card-subtitle
                  class="text-center text-h5 EoleBlue--text font-weight-bold"
                  style="font-size: 0.9rem !important"
                  >600€ de CA potentiels
                </v-card-subtitle>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-tabs-items v-model="tabMain" style="background-color: transparent !important">
          <v-tab-item :value="'tab-setup'">
            <v-row>
              <v-col cols="12" md="2">
                <v-row>
                  <v-col cols="12" md="12">
                    <v-card class="mx-auto" width="300">
                      <!-- <v-tabs vertical v-model="selectedDomainSetup" background-color="transparent">
                        <v-tab v-for="domain in domainTabs['tab-setup']" :key="domain" :href="`#${domain}`">{{
                          domain.split("-")[0]
                        }}</v-tab>
                      </v-tabs> -->
                      <v-navigation-drawer permanent>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title class="text-h6"> Décision unilatérale </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>

                        <v-divider></v-divider>

                        <v-list dense>
                          <v-list-item-group
                            v-model="selectedDomainSetup"
                            color="primary"
                            :value="true"
                            no-action
                            sub-group
                          >
                            <!-- Boucle sur chaque domaine -->
                            <template v-for="(domain, index) in domainTabs['tab-setup']">
                              <v-list-item
                                :key="'domain-' + index"
                                :value="domain.value"
                                @click="changeTab(domain.value)"
                              >
                                <v-list-item-icon>
                                  <v-icon :color="selectedDomainSetup === domain.value ? 'EoleYellow' : 'EoleBlue'">{{
                                    domain.icon
                                  }}</v-icon>
                                </v-list-item-icon>

                                <v-list-item-content>
                                  <v-list-item-title>{{ domain.title }}</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>

                              <!-- Élément 'Autres' toujours visible sous chaque domaine -->
                              <!-- <v-list-item :key="'other-' + index" class="text-right">
                                <v-list-item-content>
                                  <v-list-item-title>Ajouter un article</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item> -->
                            </template>
                          </v-list-item-group>
                        </v-list>
                      </v-navigation-drawer>
                    </v-card>
                  </v-col>
                  <v-col>
                    <v-card>
                      <v-navigation-drawer permanent>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title class="text-h6"> Outils </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>

                        <v-divider></v-divider>
                        <v-list dense>
                          <v-dialog max-width="1200" v-model="dialogAddArticle">
                            <template v-slot:activator="{ on, attrs }">
                              <v-list-item
                                v-on="on"
                                v-bind="attrs"
                                @click="openDialogAddArticle"
                                :disabled="articlesSave || selectedDomainSetup === 'tab-parameters'"
                              >
                                <v-list-item-icon>
                                  <v-icon size="20">fa-folder-plus</v-icon>
                                </v-list-item-icon>

                                <v-list-item-content>
                                  <v-list-item-title>Ajouter un article</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </template>

                            <v-card class="rounded-l-xl" style="box-shadow: 0 0 60px -20px #000000d9">
                              <v-card-text style="background-color: #f1f4f9">
                                <v-row>
                                  <v-col cols="12" md="3" class="no-padding ml-n2">
                                    <v-card class="rounded-l-xl" color="#f1f4f9" style="background-color: #f1f4f9" flat>
                                      <v-card-title>
                                        <v-img
                                          :src="require('@/assets/images/logos/anavel_logo.png')"
                                          max-height="150px"
                                          max-width="120px"
                                          alt="logo"
                                          contain
                                        ></v-img>
                                      </v-card-title>
                                      <v-card-title class="text-center justify-center muller-capitalized">
                                        Mes Services Assurance</v-card-title
                                      >
                                      <v-card-subtitle class="text-center justify-center"
                                        >Un logiciel ANAVEL
                                      </v-card-subtitle>
                                      <v-card-text class="no-padding">
                                        <v-img
                                          :src="require('@/assets/images/logos/layer.png')"
                                          alt="logo"
                                          width="300px"
                                          class=""
                                        >
                                        </v-img>
                                      </v-card-text>
                                    </v-card>
                                  </v-col>
                                  <v-col cols="12" md="6" class="no-padding d-flex">
                                    <v-card class="flex-fill flex d-flex flex-column" flat>
                                      <v-card-title>
                                        <span class="text-h5">Ajout d'un nouvel article</span>
                                      </v-card-title>
                                      <v-card-text class="pa-5">
                                        <v-text-field
                                          v-model="newArticle.title"
                                          label="Titre de l'article"
                                          dense
                                          outlined
                                          prepend-inner-icon="mdi-comment-text-multiple"
                                        ></v-text-field>

                                        <v-textarea
                                          ref="textareaContent"
                                          v-model="newArticle.content"
                                          label="Contenu de l'article"
                                          dense
                                          outlined
                                          prepend-inner-icon="mdi-comment-text-multiple"
                                          @drop.prevent="handleDrop"
                                          @dragover.prevent
                                        ></v-textarea>
                                        <v-textarea
                                          v-model="newArticle.comment"
                                          label="Commentaire de l'article"
                                          dense
                                          outlined
                                          prepend-inner-icon="mdi-comment-text-multiple"
                                        ></v-textarea>
                                      </v-card-text>
                                      <v-spacer></v-spacer>
                                      <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn text @click="addArticle('setup', selectedDomainSetup)"> terminer </v-btn>
                                      </v-card-actions>
                                    </v-card>
                                  </v-col>
                                  <v-col cols="12" md="3" class="no-padding d-flex ml-2">
                                    <v-card class="rounded-r-xl w-full" elevation="0" outlined>
                                      <v-card-title>
                                        <span class="text-h5">Variables existantes</span>
                                      </v-card-title>
                                      <v-divider></v-divider>
                                      <v-list dense>
                                        <!-- Génération dynamique de listes d'items pour différentes variables -->
                                        <v-list-item
                                          v-for="varName in existantVariables"
                                          :key="varName.variable"
                                          @click="insertVariableInNewArticle(varName.variable)"
                                        >
                                          <v-list-item-icon>
                                            <v-icon size="15">fa-plus</v-icon>
                                          </v-list-item-icon>
                                          <v-list-item-content>
                                            <v-list-item-title>{{ varName.description }}</v-list-item-title>

                                            <v-list-item-subtitle class="text-right">
                                              <v-chip small color="EoleYellow" class="white--text"
                                                >{{
                                                  getNumberOfThisVariableAreInText(varName.variable)
                                                }}
                                                référence(s)</v-chip
                                              >
                                            </v-list-item-subtitle>
                                          </v-list-item-content>
                                        </v-list-item>
                                      </v-list>
                                    </v-card>
                                    <!-- <v-card-text v-for="variable in existantVariables" :key="variable">
                                      <v-btn small>{{ variable.description }}</v-btn>
                                    </v-card-text> -->
                                  </v-col>
                                </v-row>
                              </v-card-text>
                            </v-card>
                          </v-dialog>

                          <v-list-item disabled>
                            <v-list-item-icon>
                              <v-icon size="20">fa-square-root-variable</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                              <v-list-item-title>Insérer une variable</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item disabled>
                            <v-list-item-icon>
                              <v-icon size="20">fa-signature</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>Signer la DUE</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>

                        <v-divider></v-divider>
                      </v-navigation-drawer>
                    </v-card>
                  </v-col>

                  <v-col>
                    <v-card>
                      <v-navigation-drawer permanent>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title class="text-h6"> Administration </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>

                        <v-divider></v-divider>
                        <v-list dense>
                          <v-list-item-group
                            v-model="selectedDomainSetup"
                            color="primary"
                            :value="true"
                            no-action
                            sub-group
                            @click="selectedDomainSetup = 'tab-parameters'"
                          >
                            <v-list-item @click="selectedDomainSetup = 'tab-parameters'" :value="'tab-parameters'">
                              <v-list-item-icon>
                                <v-icon
                                  size="20"
                                  :color="selectedDomainSetup === 'tab-parameters' ? 'EoleYellow' : 'EoleBlue'"
                                  >fa-cog</v-icon
                                >
                              </v-list-item-icon>

                              <v-list-item-content>
                                <v-list-item-title>Catégories objectives</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>

                          <v-list-item disabled>
                            <v-list-item-icon>
                              <v-icon size="20">fa-chart-pie</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>Statistiques</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item disabled>
                            <v-list-item-icon>
                              <v-icon size="20">fa-cash-register</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>Paiements</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>

                        <v-divider></v-divider>
                      </v-navigation-drawer>
                    </v-card>
                  </v-col>
                  <!-- <v-col class="mb-5">
                    <v-btn small class="w-full" color="EoleYellow" @click="saveArticles" :loading="articlesSave"
                      >Sauvegarder la DUE {{ convertDomainKeyToName(selectedDomainSetup) }}
                    </v-btn>
                  </v-col> -->
                </v-row>
              </v-col>
              <v-col cols="12" md="10">
                <v-menu v-model="menu" :position-x="x" :position-y="y" absolute offset-y>
                  <v-list>
                    <v-list-item>
                      <v-list-item-title>
                        <v-menu offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-list-item v-bind="attrs" v-on="on">
                              <v-list-item-icon>
                                <v-icon size="20" class="mr-2" color="EoleYellow">fa-square-root-variable </v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>Insérer une variable</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                          <v-list>
                            <!-- Génération dynamique de listes d'items pour différentes variables -->
                            <v-list-item
                              v-for="varName in existantVariables"
                              :key="varName.variable"
                              @click="insertVariable(varName.variable)"
                              class="my-5"
                            >
                              <v-list-item-content>
                                <v-list-item-title>{{ varName.description }}</v-list-item-title>
                                <v-list-item-subtitle class="text-right">
                                  <v-chip x-small color="EoleYellow" class="white--text"
                                    >{{
                                      getNumberOfThisVariableAreInTextArticle(varName.variable)
                                    }}
                                    référence(s)</v-chip
                                  >
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-list-item-title>
                    </v-list-item>
                    <!-- Ajoutez d'autres v-list-item ici pour d'autres options principales si nécessaire -->
                  </v-list>
                </v-menu>
                <v-row>
                  <v-col cols="12">
                    <v-tabs
                      color="EoleYellow"
                      v-model="tabMain"
                      background-color="transparent"
                      style="box-shadow: none !important"
                    >
                      <v-tab href="#tab-setup">Mise en place d'une DUE</v-tab>
                      <v-tab href="#tab-amendment">Avenant d'une DUE</v-tab>
                    </v-tabs>
                  </v-col>
                </v-row>
                <v-divider class="mb-10"></v-divider>

                <v-tabs-items v-model="selectedDomainSetup" style="background-color: transparent !important">
                  <v-tab-item v-for="domain in domainTabs['tab-setup']" :key="domain.value" :value="domain.value">
                    <v-card
                      :loading="articlesSave"
                      :disabled="articlesSave"
                      v-for="(article, articleIndex) in articlesForDomain('setup', domain.value)"
                      :key="article.id"
                      class="mb-4"
                    >
                      <v-card-title>
                        <v-row>
                          <v-col cols="12" md="4">
                            <v-text-field
                              filled
                              v-model="article.title"
                              style="font-weight: bold; font-size: 1.5rem"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="8" class="mt-2 text-right">
                            <v-menu offset-y left>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on">
                                  <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                              </template>
                              <v-list>
                                <v-dialog
                                  max-width="1200"
                                  :value="dialogSetOnline[article.id]"
                                  @input="value => (dialogSetOnline[article.id] = value)"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-list-item
                                      v-on="on"
                                      v-bind="attrs"
                                      :disabled="article.tab !== 'tab-2' || article.items[0].online === true"
                                    >
                                      <v-list-item-icon class="mr-2">
                                        <v-icon>fa-earth-europe</v-icon>
                                      </v-list-item-icon>
                                      <v-list-item-title>Mettre en ligne</v-list-item-title>
                                    </v-list-item>
                                  </template>

                                  <v-card class="rounded-l-xl" style="box-shadow: 0 0 60px -20px #000000d9">
                                    <v-card-text style="background-color: #f1f4f9">
                                      <v-row>
                                        <v-col cols="12" md="3" class="no-padding ml-n2">
                                          <v-card
                                            class="rounded-l-xl"
                                            color="#f1f4f9"
                                            style="background-color: #f1f4f9"
                                            flat
                                          >
                                            <v-card-title>
                                              <v-img
                                                :src="require('@/assets/images/logos/anavel_logo.png')"
                                                max-height="150px"
                                                max-width="120px"
                                                alt="logo"
                                                contain
                                              ></v-img>
                                            </v-card-title>
                                            <v-card-title class="text-center justify-center muller-capitalized">
                                              Mes Services Assurance</v-card-title
                                            >
                                            <v-card-subtitle class="text-center justify-center"
                                              >Un logiciel ANAVEL
                                            </v-card-subtitle>
                                            <v-card-text class="no-padding">
                                              <v-img
                                                :src="require('@/assets/images/logos/layer.png')"
                                                alt="logo"
                                                width="300px"
                                                class=""
                                              >
                                              </v-img>
                                            </v-card-text>
                                          </v-card>
                                        </v-col>
                                        <v-col cols="12" md="6" class="no-padding d-flex">
                                          <v-card class="flex-fill flex d-flex flex-column" flat>
                                            <v-card-title>
                                              <span class="text-h5">Mise en ligne</span>
                                            </v-card-title>

                                            <v-card-text class="pa-5">
                                              <vue-pincode
                                                ref="pincodeInput"
                                                @pincode="pincode => checkPincodeOnline(pincode, article)"
                                              />
                                            </v-card-text>
                                            <v-spacer></v-spacer>
                                          </v-card>
                                        </v-col>
                                        <v-col cols="12" md="3" class="no-padding d-flex ml-2">
                                          <v-card
                                            class="rounded-r-xl w-full d-flex flex-column align-center justify-center"
                                            elevation="0"
                                            outlined
                                          >
                                            <v-card-text class="text-center">
                                              <i
                                                class="fa-solid fa-triangle-exclamation fa-4x EoleYellow--text fa-beat"
                                              ></i>
                                            </v-card-text>
                                            <v-card-text class="text-center">
                                              Ce texte s'apprête à être envoyé en production. Veuillez vérifier que tout
                                              est correct avant de valider.
                                            </v-card-text>
                                            <v-card-text class="text-center">
                                              Une double authentification sera nécessaire pour valider cette action.
                                            </v-card-text>
                                          </v-card>
                                        </v-col>
                                      </v-row>
                                    </v-card-text>
                                  </v-card>
                                </v-dialog>
                                <v-list-item v-if="article.tab === 'tab-2'" @click="archiveArticleSetup(article)">
                                  <v-list-item-icon class="mr-2">
                                    <v-icon>fa-box-archive</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-title>Archiver</v-list-item-title>
                                </v-list-item>

                                <v-dialog
                                  max-width="1200"
                                  :value="dialogSetProduction[article.id]"
                                  @input="value => (dialogSetProduction[article.id] = value)"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-list-item :disabled="article.tab !== 'tab-3'" v-on="on" v-bind="attrs">
                                      <v-list-item-icon class="mr-2">
                                        <v-icon>fa-right-left</v-icon>
                                      </v-list-item-icon>
                                      <v-list-item-title> Envoyer en production</v-list-item-title>
                                    </v-list-item>
                                  </template>

                                  <v-card class="rounded-l-xl" style="box-shadow: 0 0 60px -20px #000000d9">
                                    <v-card-text style="background-color: #f1f4f9">
                                      <v-row>
                                        <v-col cols="12" md="3" class="no-padding ml-n2">
                                          <v-card
                                            class="rounded-l-xl"
                                            color="#f1f4f9"
                                            style="background-color: #f1f4f9"
                                            flat
                                          >
                                            <v-card-title>
                                              <v-img
                                                :src="require('@/assets/images/logos/anavel_logo.png')"
                                                max-height="150px"
                                                max-width="120px"
                                                alt="logo"
                                                contain
                                              ></v-img>
                                            </v-card-title>
                                            <v-card-title class="text-center justify-center muller-capitalized">
                                              Mes Services Assurance</v-card-title
                                            >
                                            <v-card-subtitle class="text-center justify-center"
                                              >Un logiciel ANAVEL
                                            </v-card-subtitle>
                                            <v-card-text class="no-padding">
                                              <v-img
                                                :src="require('@/assets/images/logos/layer.png')"
                                                alt="logo"
                                                width="300px"
                                                class=""
                                              >
                                              </v-img>
                                            </v-card-text>
                                          </v-card>
                                        </v-col>
                                        <v-col cols="12" md="6" class="no-padding d-flex">
                                          <v-card class="flex-fill flex d-flex flex-column" flat>
                                            <v-card-title>
                                              <span class="text-h5">Article : {{ article.title }}</span>
                                            </v-card-title>
                                            <v-tabs-items
                                              v-model="tabProduction"
                                              style="background-color: transparent !important"
                                            >
                                              <v-tab-item :value="'tab-1'">
                                                <v-card-text class="pa-5">
                                                  <v-textarea
                                                    label="Contenu de l'article"
                                                    v-model="article.items[1].content"
                                                    outlined
                                                    dense
                                                    readonly
                                                  >
                                                  </v-textarea>
                                                  <v-textarea
                                                    label="Commentaire de l'article"
                                                    v-model="article.items[1].comment"
                                                    outlined
                                                    dense
                                                    readonly
                                                  >
                                                  </v-textarea>
                                                </v-card-text>
                                                <v-spacer></v-spacer>

                                                <v-card-actions>
                                                  <v-spacer></v-spacer>
                                                  <v-btn text @click="tabProduction = `tab-2`">
                                                    Relecture terminer
                                                  </v-btn>
                                                </v-card-actions>
                                              </v-tab-item>

                                              <v-tab-item :value="'tab-2'">
                                                <v-card-text class="pa-5">
                                                  <vue-pincode
                                                    ref="pincodeInput"
                                                    @pincode="pincode => checkPincode(pincode, article)"
                                                  />
                                                </v-card-text>
                                              </v-tab-item>
                                            </v-tabs-items>
                                          </v-card>
                                        </v-col>
                                        <v-col cols="12" md="3" class="no-padding d-flex ml-2">
                                          <v-card
                                            class="rounded-r-xl w-full d-flex flex-column align-center justify-center"
                                            elevation="0"
                                            outlined
                                          >
                                            <v-card-text class="text-center">
                                              <i
                                                class="fa-solid fa-triangle-exclamation fa-4x EoleYellow--text fa-beat"
                                              ></i>
                                            </v-card-text>
                                            <v-card-text class="text-center">
                                              Ce texte s'apprête à être envoyé en production. Veuillez vérifier que tout
                                              est correct avant de valider.
                                            </v-card-text>
                                            <v-card-text class="text-center">
                                              Une double authentification sera nécessaire pour valider cette action.
                                            </v-card-text>
                                          </v-card>
                                        </v-col>
                                      </v-row>
                                    </v-card-text>
                                  </v-card>
                                </v-dialog>

                                <v-list-item @click="deleteArticle(article)">
                                  <v-list-item-icon class="mr-2">
                                    <v-icon>fa-trash</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-title>Supprimer</v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </v-menu>
                          </v-col>
                        </v-row>
                      </v-card-title>
                      <v-row>
                        <v-col cols="12" md="8"
                          ><v-tabs
                            color="EoleYellow"
                            class="text-left"
                            v-model="article.tab"
                            style="box-shadow: none !important"
                            background-color="transparent"
                          >
                            <v-tab v-for="(item, index) in article.items" :href="`#${item.tab}`" v-bind:key="index">
                              {{ item.title }}
                            </v-tab>
                          </v-tabs></v-col
                        >
                        <v-col v-if="article.tab === 'tab-2'" cols="12" md="4" class="text-center mt-2"
                          ><v-chip v-if="article.items[0].online" color="EoleGreen" small> Article en ligne </v-chip>
                          <v-chip v-else color="EoleErrorBase" small> Article hors ligne </v-chip>
                        </v-col>
                        <v-col v-else cols="12" md="4" class="text-center mt-2">
                          <v-chip v-if="!article.unsavedChanges" color="EoleErrorBase" small>
                            Modification non enregistrée
                          </v-chip>
                          <v-chip v-else color="EoleGreen" small> Modification enregistrée </v-chip>

                          <v-btn v-if="!article.unsavedChanges" @click="saveArticle(article)" fab icon>
                            <i class="fa-solid fa-save"></i>
                          </v-btn>
                        </v-col>
                      </v-row>
                      <v-divider></v-divider>
                      <v-tabs-items v-model="article.tab" style="background-color: transparent !important">
                        <v-tab-item v-for="(item, itemIndex) in article.items" :value="item.tab" v-bind:key="itemIndex">
                          <v-card-text class="mt-10">
                            <vue2-tinymce-editor
                              v-if="item.title === 'En préproduction'"
                              :id="`tinymce-${article.id}-${itemIndex}`"
                              v-model="item.content"
                              @input="onContentChange(article)"
                            ></vue2-tinymce-editor>
                            <v-textarea
                              v-else
                              :ref="`textarea-${article.id}-${itemIndex}`"
                              :readonly="item.title !== 'En préproduction'"
                              @contextmenu.prevent="
                                openMenu($event, 'setup', selectedDomainSetup, article.id, itemIndex)
                              "
                              :label="`Contenu de l'article ${itemIndex + 1}`"
                              v-model="item.content"
                              dense
                              outlined
                              prepend-inner-icon="mdi-comment-text-multiple"
                              @input="onContentChange(article)"
                              auto-grow
                            ></v-textarea>
                          </v-card-text>

                          <v-card-text v-if="item.title === 'En préproduction'">
                            {{ item.title }}
                            <vue2-tinymce-editor
                              v-if="item.title === 'En préproduction'"
                              :id="`tinymce-${article.id}-${itemIndex}`"
                              v-model="item.comment"
                              @input="onContentChange(article)"
                            ></vue2-tinymce-editor>
                          </v-card-text>

                          <v-card-text v-else class="mt-n5">
                            <v-textarea
                              :readonly="item.title !== 'En préproduction'"
                              :label="`Commentaire de l'article ${itemIndex + 1}`"
                              v-model="item.comment"
                              dense
                              outlined
                              prepend-inner-icon="mdi-comment-text-multiple"
                              @input="onContentChange(article)"
                              auto-grow
                            ></v-textarea>
                          </v-card-text>
                        </v-tab-item>
                      </v-tabs-items>
                    </v-card>
                    <span v-if="articlesForDomain('setup', domain.value).length === 0"
                      >Il n'y a aucun article disponible pour le moment.</span
                    >
                  </v-tab-item>
                  <v-tab-item value="tab-parameters">
                    <v-card>
                      <v-tabs v-model="tabCCN">
                        <v-tab href="#tab-CCN1">En prod</v-tab>
                        <v-tab href="#tab-CCN1">En préprod</v-tab>
                      </v-tabs>
                      <v-card-text>
                        <v-switch
                          class="font-weight-bold"
                          label=" DÉFINITION PAR DÉFAUT POUR LES CADRES"
                          inset
                        ></v-switch>
                        <v-data-table
                          dense
                          hide-default-footer
                          :items="defaultColleages[0].colleages"
                          :headers="headersCadre"
                        >
                          <template v-slot:item.designation="props">
                            <v-edit-dialog
                              :return-value.sync="props.item.designation"
                              @save="saveColleageDefault(props.item)"
                            >
                              {{ props.item.designation }}
                              <template v-slot:input>
                                <v-text-field
                                  v-model="props.item.designation"
                                  :rules="[max25chars]"
                                  label="Edit"
                                  single-line
                                  counter
                                ></v-text-field>
                              </template>
                            </v-edit-dialog>
                          </template>
                        </v-data-table>
                      </v-card-text>
                      <v-card-text>
                        <v-switch
                          class="font-weight-bold"
                          label=" DÉFINITION PAR DÉFAUT POUR LES NON CADRES"
                          inset
                        ></v-switch>

                        <v-data-table
                          dense
                          hide-default-footer
                          :items="defaultColleages[1].colleages"
                          :headers="headersCadre"
                        >
                          <template v-slot:item.designation="props">
                            <v-edit-dialog
                              :return-value.sync="props.item.designation"
                              @save="saveColleageDefault(props.item)"
                            >
                              {{ props.item.designation }}
                              <template v-slot:input>
                                <v-text-field
                                  v-model="props.item.designation"
                                  :rules="[max25chars]"
                                  label="Edit"
                                  single-line
                                  counter
                                ></v-text-field>
                              </template>
                            </v-edit-dialog>
                          </template>
                        </v-data-table>
                      </v-card-text>

                      <v-card-text>
                        <v-row>
                          <v-col cols="12" md="6">
                            <v-switch
                              class="font-weight-bold"
                              label=" DÉFINITION SPÉCIFIQUE POUR CERTAINES CCN"
                              inset
                            ></v-switch>
                          </v-col>
                          <v-col cols="12" md="6" class="text-right">
                            <v-dialog max-width="1200" v-model="dialogAddSpecificCCN">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn v-on="on" v-bind="attrs" fab icon>
                                  <i class="fa-sold fa-plus EoleBlue--text"></i>
                                </v-btn>
                              </template>

                              <v-card class="rounded-l-xl" style="box-shadow: 0 0 60px -20px #000000d9">
                                <v-card-text style="background-color: #f1f4f9">
                                  <v-row>
                                    <v-col cols="12" md="3" class="no-padding ml-n2">
                                      <v-card
                                        class="rounded-l-xl"
                                        color="#f1f4f9"
                                        style="background-color: #f1f4f9"
                                        flat
                                      >
                                        <v-card-title>
                                          <v-img
                                            :src="require('@/assets/images/logos/anavel_logo.png')"
                                            max-height="150px"
                                            max-width="120px"
                                            alt="logo"
                                            contain
                                          ></v-img>
                                        </v-card-title>
                                        <v-card-title class="text-center justify-center muller-capitalized">
                                          Mes Services Assurance</v-card-title
                                        >
                                        <v-card-subtitle class="text-center justify-center"
                                          >Un logiciel ANAVEL
                                        </v-card-subtitle>
                                        <v-card-text class="no-padding">
                                          <v-img
                                            :src="require('@/assets/images/logos/layer.png')"
                                            alt="logo"
                                            width="300px"
                                            class=""
                                          >
                                          </v-img>
                                        </v-card-text>
                                      </v-card>
                                    </v-col>
                                    <v-col cols="12" md="9" class="no-padding d-flex">
                                      <v-card class="flex-fill flex d-flex flex-column" flat>
                                        <v-card-title>
                                          <span class="text-h5">Ajout d'un nouvel article</span>
                                        </v-card-title>
                                        <v-card-text class="pa-5">
                                          <v-autocomplete
                                            :filter="customFilter"
                                            multiple
                                            class="mt-5"
                                            label="Convention collective"
                                            v-model="selectedCCN"
                                            :items="collectiveAgreements"
                                            dense
                                            item-text="designation"
                                            outlined
                                            return-object
                                          >
                                            <template v-slot:item="data">
                                              {{ data.item.designation }} - {{ data.item.idcc }}
                                            </template>
                                          </v-autocomplete>
                                        </v-card-text>
                                        <v-spacer></v-spacer>
                                        <v-card-actions>
                                          <v-spacer></v-spacer>
                                          <v-btn text @click="postColleageSpecific(selectedCCN)"> terminer </v-btn>
                                        </v-card-actions>
                                      </v-card>
                                    </v-col>
                                  </v-row>
                                </v-card-text>
                              </v-card>
                            </v-dialog>

                            <!-- <v-autocomplete
                              class="mt-5"
                              label="Convention collective"
                              v-model="selectedCCN"
                              :items="collectiveAgreements"
                              dense
                              item-text="designation"
                              outlined
                            >
                              <template v-slot:item="data"> {{ data.item.designation }} - {{ data.item.idcc }} </template>
                            </v-autocomplete> -->
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-text-field
                              prepend-inner-icon="mdi-magnify"
                              label="Rechercher une CCN"
                              v-model="filterCollectiveAgreement"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <div
                          v-for="(ccn, index) in filterSpecificCollectiveAgreements(specificCollectiveAgreements)"
                          v-bind:key="index"
                          class="mb-5"
                        >
                          <!-- <v-divider v-if="index > 0" class="my-5"></v-divider> -->
                          <v-card class="rounded-xl" flat outlined>
                            <v-row>
                              <v-col cols="12" md="10">
                                <v-card-title>{{ ccn.designation }} ({{ ccn.idcc }})</v-card-title>
                              </v-col>
                              <v-col cols="12" md="2" class="mt-3 text-right pr-10">
                                <v-btn icon @click="deleteSpecificCCN(ccn)" color="EoleError">
                                  <v-icon color="EoleError">mdi-trash-can</v-icon>
                                </v-btn>
                              </v-col>
                            </v-row>
                            <v-card-text>
                              <v-row>
                                <v-col
                                  cols="12"
                                  md="6"
                                  v-for="(colleage, index) in ccn.colleages_types"
                                  v-bind:key="index"
                                >
                                  <v-data-table
                                    dense
                                    v-if="ccn.colleages_types.length > 0"
                                    hide-default-footer
                                    :items="colleage.colleages"
                                    :headers="[{ text: colleage.designation, value: 'designation' }]"
                                  >
                                    <template v-slot:item.designation="props">
                                      <v-edit-dialog
                                        :return-value.sync="props.item.designation"
                                        @save="saveColleageSpecific(props.item)"
                                      >
                                        {{ props.item.designation }}
                                        <template v-slot:input>
                                          <v-text-field
                                            v-model="props.item.designation"
                                            :rules="[max25chars]"
                                            label="Edit"
                                            single-line
                                            counter
                                          ></v-text-field>
                                        </template>
                                      </v-edit-dialog>
                                    </template>
                                  </v-data-table>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </div>
                        {{ ccn }}
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item :value="'tab-amendment'">
            <v-row>
              <v-col cols="12" md="2">
                <v-row>
                  <v-col cols="12" md="12">
                    <v-card>
                      <!-- <v-tabs vertical v-model="selectedDomainSetup" background-color="transparent">
                        <v-tab v-for="domain in domainTabs['tab-setup']" :key="domain" :href="`#${domain}`">{{
                          domain.split("-")[0]
                        }}</v-tab>
                      </v-tabs> -->
                      <v-navigation-drawer permanent>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title class="text-h6"> Catégories </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>

                        <v-divider></v-divider>

                        <v-list dense>
                          <v-list-item-group
                            v-model="selectedDomainAmendment"
                            color="primary"
                            :value="true"
                            no-action
                            sub-group
                          >
                            <v-list-item
                              v-for="(domain, index) in domainTabs['tab-amendment']"
                              :key="index"
                              :value="domain.value"
                              @click="selectedDomainAmendment = domain.value"
                            >
                              <template v-slot:default="{ active }">
                                <v-list-item-icon>
                                  <v-icon :color="active ? 'EoleYellow' : 'EoleBlue'">{{ domain.icon }}</v-icon>
                                </v-list-item-icon>

                                <v-list-item-content>
                                  <v-list-item-title>{{ domain.title }}</v-list-item-title>
                                </v-list-item-content>
                              </template>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </v-navigation-drawer>
                    </v-card>
                  </v-col>
                  <v-col>
                    <v-card>
                      <v-navigation-drawer permanent>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title class="text-h6"> Outils </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>

                        <v-divider></v-divider>
                        <v-list dense>
                          <v-dialog max-width="1200" v-model="dialogAddArticle">
                            <template v-slot:activator="{ on, attrs }">
                              <v-list-item
                                v-on="on"
                                v-bind="attrs"
                                @click="openDialogAddArticle"
                                :disabled="articlesSave"
                              >
                                <v-list-item-icon>
                                  <v-icon size="20">fa-folder-plus</v-icon>
                                </v-list-item-icon>

                                <v-list-item-content>
                                  <v-list-item-title>Ajouter un article</v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </template>

                            <v-card class="rounded-l-xl" style="box-shadow: 0 0 60px -20px #000000d9">
                              <v-card-text style="background-color: #f1f4f9">
                                <v-row>
                                  <v-col cols="12" md="3" class="no-padding ml-n2">
                                    <v-card class="rounded-l-xl" color="#f1f4f9" style="background-color: #f1f4f9" flat>
                                      <v-card-title>
                                        <v-img
                                          :src="require('@/assets/images/logos/anavel_logo.png')"
                                          max-height="150px"
                                          max-width="120px"
                                          alt="logo"
                                          contain
                                        ></v-img>
                                      </v-card-title>
                                      <v-card-title class="text-center justify-center muller-capitalized">
                                        Mes Services Assurance</v-card-title
                                      >
                                      <v-card-subtitle class="text-center justify-center"
                                        >Un logiciel ANAVEL
                                      </v-card-subtitle>
                                      <v-card-text class="no-padding">
                                        <v-img
                                          :src="require('@/assets/images/logos/layer.png')"
                                          alt="logo"
                                          width="300px"
                                          class=""
                                        >
                                        </v-img>
                                      </v-card-text>
                                    </v-card>
                                  </v-col>
                                  <v-col cols="12" md="6" class="no-padding d-flex">
                                    <v-card class="flex-fill flex d-flex flex-column" flat>
                                      <v-card-title>
                                        <span class="text-h5">Ajout d'un nouvel article</span>
                                      </v-card-title>
                                      <v-card-text class="pa-5">
                                        <v-text-field
                                          v-model="newArticle.title"
                                          label="Titre de l'article"
                                          dense
                                          outlined
                                          prepend-inner-icon="mdi-comment-text-multiple"
                                        ></v-text-field>

                                        <v-textarea
                                          ref="textareaContent"
                                          v-model="newArticle.content"
                                          label="Contenu de l'article"
                                          dense
                                          outlined
                                          prepend-inner-icon="mdi-comment-text-multiple"
                                          @drop.prevent="handleDrop"
                                          @dragover.prevent
                                        ></v-textarea>
                                        <v-textarea
                                          v-model="newArticle.comment"
                                          label="Commentaire de l'article"
                                          dense
                                          outlined
                                          prepend-inner-icon="mdi-comment-text-multiple"
                                        ></v-textarea>
                                      </v-card-text>
                                      <v-spacer></v-spacer>
                                      <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn text @click="addArticle('amendment', selectedDomainAmendment)">
                                          terminer
                                        </v-btn>
                                      </v-card-actions>
                                    </v-card>
                                  </v-col>
                                  <v-col cols="12" md="3" class="no-padding d-flex ml-2">
                                    <v-card class="rounded-r-xl w-full" elevation="0" outlined>
                                      <v-card-title>
                                        <span class="text-h5">Variables existantes</span>
                                      </v-card-title>
                                      <v-divider></v-divider>
                                      <v-list dense>
                                        <!-- Génération dynamique de listes d'items pour différentes variables -->
                                        <v-list-item
                                          v-for="varName in existantVariables"
                                          :key="varName.variable"
                                          @click="insertVariableInNewArticle(varName.variable)"
                                        >
                                          <v-list-item-icon>
                                            <v-icon size="15">fa-plus</v-icon>
                                          </v-list-item-icon>
                                          <v-list-item-content>
                                            <v-list-item-title>{{ varName.description }}</v-list-item-title>

                                            <v-list-item-subtitle class="text-right">
                                              <v-chip small color="EoleYellow" class="white--text"
                                                >{{
                                                  getNumberOfThisVariableAreInText(varName.variable)
                                                }}
                                                référence(s)</v-chip
                                              >
                                            </v-list-item-subtitle>
                                          </v-list-item-content>
                                        </v-list-item>
                                      </v-list>
                                    </v-card>
                                    <!-- <v-card-text v-for="variable in existantVariables" :key="variable">
                                      <v-btn small>{{ variable.description }}</v-btn>
                                    </v-card-text> -->
                                  </v-col>
                                </v-row>
                              </v-card-text>
                            </v-card>
                          </v-dialog>

                          <v-list-item disabled>
                            <v-list-item-icon>
                              <v-icon size="20">fa-square-root-variable</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                              <v-list-item-title>Insérer une variable</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item disabled>
                            <v-list-item-icon>
                              <v-icon size="20">fa-signature</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>Signer la DUE</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item disabled>
                            <v-list-item-icon>
                              <v-icon size="20">fa-chart-pie</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>Statistiques</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item disabled>
                            <v-list-item-icon>
                              <v-icon size="20">fa-cash-register</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>Paiements</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-navigation-drawer>
                    </v-card>
                  </v-col>

                  <v-col>
                    <v-card>
                      <v-navigation-drawer permanent>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title class="text-h6"> Administration </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>

                        <v-divider></v-divider>
                        <v-list dense>
                          <v-list-item-group
                            v-model="selectedDomainAmendment"
                            color="primary"
                            :value="true"
                            no-action
                            sub-group
                            @click="selectedDomainAmendment = 'tab-parameters'"
                          >
                            <v-list-item @click="selectedDomainAmendment = 'tab-parameters'" :value="'tab-parameters'">
                              <v-list-item-icon>
                                <v-icon
                                  size="20"
                                  :color="selectedDomainAmendment === 'tab-parameters' ? 'EoleYellow' : 'EoleBlue'"
                                  >fa-cog</v-icon
                                >
                              </v-list-item-icon>

                              <v-list-item-content>
                                <v-list-item-title>Catégories objectives</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>

                          <v-list-item disabled>
                            <v-list-item-icon>
                              <v-icon size="20">fa-chart-pie</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>Statistiques</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>

                          <v-list-item disabled>
                            <v-list-item-icon>
                              <v-icon size="20">fa-cash-register</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>Paiements</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>

                        <v-divider></v-divider>
                      </v-navigation-drawer>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="10">
                <v-menu v-model="menu" :position-x="x" :position-y="y" absolute offset-y>
                  <v-list>
                    <v-list-item>
                      <v-list-item-title>
                        <v-menu offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-list-item v-bind="attrs" v-on="on">
                              <v-list-item-icon>
                                <v-icon size="20" class="mr-2" color="EoleYellow">fa-square-root-variable </v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title>Insérer une variable</v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </template>
                          <v-list>
                            <!-- Génération dynamique de listes d'items pour différentes variables -->
                            <v-list-item
                              v-for="varName in existantVariables"
                              :key="varName.variable"
                              @click="insertVariable(varName.variable)"
                              class="my-5"
                            >
                              <v-list-item-content>
                                <v-list-item-title>{{ varName.description }}</v-list-item-title>
                                <v-list-item-subtitle class="text-right">
                                  <v-chip x-small color="EoleYellow" class="white--text"
                                    >{{
                                      getNumberOfThisVariableAreInTextArticle(varName.variable)
                                    }}
                                    référence(s)</v-chip
                                  >
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-list-item-title>
                    </v-list-item>
                    <!-- Ajoutez d'autres v-list-item ici pour d'autres options principales si nécessaire -->
                  </v-list>
                </v-menu>
                <v-row class="mb-5">
                  <v-col cols="12">
                    <v-tabs
                      color="EoleYellow"
                      v-model="tabMain"
                      background-color="transparent"
                      style="box-shadow: none !important"
                    >
                      <v-tab href="#tab-setup">Mise en place d'une DUE</v-tab>
                      <v-tab href="#tab-amendment">Avenant d'une DUE</v-tab>
                    </v-tabs>
                  </v-col>
                </v-row>
                <v-divider class="mb-10"></v-divider>

                <v-tabs-items v-model="selectedDomainAmendment" style="background-color: transparent !important">
                  <v-tab-item v-for="domain in domainTabs['tab-amendment']" :key="domain.value" :value="domain.value">
                    <v-card
                      v-for="(article, articleIndex) in articlesForDomain('amendment', domain.value)"
                      :key="article.id"
                      class="mb-4"
                    >
                      <v-card-title>
                        <v-row>
                          <v-col cols="12" md="11">
                            <v-text-field
                              v-model="article.title"
                              style="font-weight: bold; font-size: 1.5rem"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="1" class="mt-2">
                            <v-menu offset-y left>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn icon v-bind="attrs" v-on="on">
                                  <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                              </template>
                              <v-list>
                                <v-dialog
                                  max-width="1200"
                                  :value="dialogSetOnline[article.id]"
                                  @input="value => (dialogSetOnline[article.id] = value)"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-list-item
                                      v-on="on"
                                      v-bind="attrs"
                                      v-if="article.tab === 'tab-2' && article.items[0].online === false"
                                    >
                                      <v-list-item-icon class="mr-2">
                                        <v-icon>fa-earth-europe</v-icon>
                                      </v-list-item-icon>
                                      <v-list-item-title>Mettre en ligne</v-list-item-title>
                                    </v-list-item>
                                  </template>

                                  <v-card class="rounded-l-xl" style="box-shadow: 0 0 60px -20px #000000d9">
                                    <v-card-text style="background-color: #f1f4f9">
                                      <v-row>
                                        <v-col cols="12" md="3" class="no-padding ml-n2">
                                          <v-card
                                            class="rounded-l-xl"
                                            color="#f1f4f9"
                                            style="background-color: #f1f4f9"
                                            flat
                                          >
                                            <v-card-title>
                                              <v-img
                                                :src="require('@/assets/images/logos/anavel_logo.png')"
                                                max-height="150px"
                                                max-width="120px"
                                                alt="logo"
                                                contain
                                              ></v-img>
                                            </v-card-title>
                                            <v-card-title class="text-center justify-center muller-capitalized">
                                              Mes Services Assurance</v-card-title
                                            >
                                            <v-card-subtitle class="text-center justify-center"
                                              >Un logiciel ANAVEL
                                            </v-card-subtitle>
                                            <v-card-text class="no-padding">
                                              <v-img
                                                :src="require('@/assets/images/logos/layer.png')"
                                                alt="logo"
                                                width="300px"
                                                class=""
                                              >
                                              </v-img>
                                            </v-card-text>
                                          </v-card>
                                        </v-col>
                                        <v-col cols="12" md="6" class="no-padding d-flex">
                                          <v-card class="flex-fill flex d-flex flex-column" flat>
                                            <v-card-title>
                                              <span class="text-h5">Mise en ligne</span>
                                            </v-card-title>

                                            <v-card-text class="pa-5">
                                              <vue-pincode
                                                ref="pincodeInput"
                                                @pincode="pincode => checkPincodeOnline(pincode, article)"
                                              />
                                            </v-card-text>
                                            <v-spacer></v-spacer>
                                          </v-card>
                                        </v-col>
                                        <v-col cols="12" md="3" class="no-padding d-flex ml-2">
                                          <v-card
                                            class="rounded-r-xl w-full d-flex flex-column align-center justify-center"
                                            elevation="0"
                                            outlined
                                          >
                                            <v-card-text class="text-center">
                                              <i
                                                class="fa-solid fa-triangle-exclamation fa-4x EoleYellow--text fa-beat"
                                              ></i>
                                            </v-card-text>
                                            <v-card-text class="text-center">
                                              Ce texte s'apprête à être envoyé en production. Veuillez vérifier que tout
                                              est correct avant de valider.
                                            </v-card-text>
                                            <v-card-text class="text-center">
                                              Une double authentification sera nécessaire pour valider cette action.
                                            </v-card-text>
                                          </v-card>
                                        </v-col>
                                      </v-row>
                                    </v-card-text>
                                  </v-card>
                                </v-dialog>

                                <v-list-item v-if="article.tab === 'tab-2'" @click="archiveArticle(article)">
                                  <v-list-item-icon class="mr-2">
                                    <v-icon>fa-box-archive</v-icon>
                                  </v-list-item-icon>
                                  <v-list-item-title>Archiver</v-list-item-title>
                                </v-list-item>

                                <v-dialog
                                  max-width="1200"
                                  :value="dialogSetProduction[article.id]"
                                  @input="value => (dialogSetProduction[article.id] = value)"
                                >
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-list-item :disabled="article.tab !== 'tab-3'" v-on="on" v-bind="attrs">
                                      <v-list-item-icon class="mr-2">
                                        <v-icon>fa-right-left</v-icon>
                                      </v-list-item-icon>
                                      <v-list-item-title> Envoyer en production</v-list-item-title>
                                    </v-list-item>
                                  </template>

                                  <v-card class="rounded-l-xl" style="box-shadow: 0 0 60px -20px #000000d9">
                                    <v-card-text style="background-color: #f1f4f9">
                                      <v-row>
                                        <v-col cols="12" md="3" class="no-padding ml-n2">
                                          <v-card
                                            class="rounded-l-xl"
                                            color="#f1f4f9"
                                            style="background-color: #f1f4f9"
                                            flat
                                          >
                                            <v-card-title>
                                              <v-img
                                                :src="require('@/assets/images/logos/anavel_logo.png')"
                                                max-height="150px"
                                                max-width="120px"
                                                alt="logo"
                                                contain
                                              ></v-img>
                                            </v-card-title>
                                            <v-card-title class="text-center justify-center muller-capitalized">
                                              Mes Services Assurance</v-card-title
                                            >
                                            <v-card-subtitle class="text-center justify-center"
                                              >Un logiciel ANAVEL
                                            </v-card-subtitle>
                                            <v-card-text class="no-padding">
                                              <v-img
                                                :src="require('@/assets/images/logos/layer.png')"
                                                alt="logo"
                                                width="300px"
                                                class=""
                                              >
                                              </v-img>
                                            </v-card-text>
                                          </v-card>
                                        </v-col>
                                        <v-col cols="12" md="6" class="no-padding d-flex">
                                          <v-card class="flex-fill flex d-flex flex-column" flat>
                                            <v-card-title>
                                              <span class="text-h5">Article : {{ article.title }}</span>
                                            </v-card-title>
                                            <v-tabs-items
                                              v-model="tabProduction"
                                              style="background-color: transparent !important"
                                            >
                                              <v-tab-item :value="'tab-1'">
                                                <v-card-text class="pa-5">
                                                  <v-textarea
                                                    label="Contenu de l'article"
                                                    v-model="article.items[1].content"
                                                    outlined
                                                    dense
                                                    readonly
                                                  >
                                                  </v-textarea>
                                                  <v-textarea
                                                    label="Commentaire de l'article"
                                                    v-model="article.items[1].comment"
                                                    outlined
                                                    dense
                                                    readonly
                                                  >
                                                  </v-textarea>
                                                </v-card-text>
                                                <v-spacer></v-spacer>

                                                <v-card-actions>
                                                  <v-spacer></v-spacer>
                                                  <v-btn text @click="tabProduction = `tab-2`">
                                                    Relecture terminer
                                                  </v-btn>
                                                </v-card-actions>
                                              </v-tab-item>

                                              <v-tab-item :value="'tab-2'">
                                                <v-card-text class="pa-5">
                                                  <vue-pincode
                                                    ref="pincodeInput"
                                                    @pincode="pincode => checkPincode(pincode, article)"
                                                  />
                                                </v-card-text>
                                              </v-tab-item>
                                            </v-tabs-items>
                                          </v-card>
                                        </v-col>
                                        <v-col cols="12" md="3" class="no-padding d-flex ml-2">
                                          <v-card
                                            class="rounded-r-xl w-full d-flex flex-column align-center justify-center"
                                            elevation="0"
                                            outlined
                                          >
                                            <v-card-text class="text-center">
                                              <i
                                                class="fa-solid fa-triangle-exclamation fa-4x EoleYellow--text fa-beat"
                                              ></i>
                                            </v-card-text>
                                            <v-card-text class="text-center">
                                              Ce texte s'apprête à être envoyé en production. Veuillez vérifier que tout
                                              est correct avant de valider.
                                            </v-card-text>
                                            <v-card-text class="text-center">
                                              Une double authentification sera nécessaire pour valider cette action.
                                            </v-card-text>
                                          </v-card>
                                          <!-- <v-card-text v-for="variable in existantVariables" :key="variable">
                                      <v-btn small>{{ variable.description }}</v-btn>
                                    </v-card-text> -->
                                        </v-col>
                                      </v-row>
                                    </v-card-text>
                                  </v-card>
                                </v-dialog>
                              </v-list>
                            </v-menu>
                          </v-col>
                        </v-row>
                      </v-card-title>
                      <v-row>
                        <v-col cols="12" md="10"
                          ><v-tabs
                            color="EoleYellow"
                            class="text-left"
                            v-model="article.tab"
                            style="box-shadow: none !important"
                            background-color="transparent"
                          >
                            <v-tab v-for="(item, index) in article.items" :href="`#${item.tab}`" v-bind:key="index">
                              {{ item.title }}
                            </v-tab>
                          </v-tabs></v-col
                        >
                        <v-col cols="12" md="2" class="text-center"
                          ><v-chip v-if="article.items[0].online" color="EoleGreen" small> Article en ligne </v-chip>
                          <v-chip v-else color="EoleErrorBase" small> Article hors ligne </v-chip>
                        </v-col>
                      </v-row>

                      <v-divider></v-divider>

                      <v-tabs-items v-model="article.tab" style="background-color: transparent !important">
                        <v-tab-item v-for="(item, itemIndex) in article.items" :value="item.tab" v-bind:key="itemIndex">
                          <v-card-text class="mt-10">
                            <v-textarea
                              :ref="`textarea-${article.id}-${itemIndex}`"
                              :readonly="item.title === 'En production'"
                              @contextmenu.prevent="
                                openMenu($event, 'amendment', selectedDomainAmendment, article.id, itemIndex)
                              "
                              :label="`Contenu de l'article ${itemIndex + 1}`"
                              v-model="item.content"
                              dense
                              outlined
                              prepend-inner-icon="mdi-comment-text-multiple"
                            ></v-textarea>
                          </v-card-text>

                          <!-- <ckeditor :editor="editor" v-model="item.content" :config="editorConfig"></ckeditor> -->

                          <v-card-text class="mt-n5">
                            <!-- <ckeditor :editor="editor" v-model="editorData" :config="editorConfig"></ckeditor> -->
                            <v-textarea
                              :readonly="item.title === 'En production'"
                              :label="`Commentaire de l'article ${itemIndex + 1}`"
                              v-model="item.comment"
                              dense
                              outlined
                              prepend-inner-icon="mdi-comment-text-multiple"
                            ></v-textarea>
                          </v-card-text>
                          <!-- Autres détails de l'article ici -->
                        </v-tab-item>
                      </v-tabs-items>
                    </v-card>
                    <span v-if="articlesForDomain('amendment', domain.value).length === 0"
                      >Il n'y a aucun article disponible pour le moment.</span
                    >
                  </v-tab-item>

                  <v-tab-item value="tab-parameters">
                    <v-card>
                      <v-tabs v-model="tabCCN">
                        <v-tab href="#tab-CCN1">En prod</v-tab>
                        <v-tab href="#tab-CCN1">En préprod</v-tab>
                      </v-tabs>
                      <v-card-text>
                        <v-switch
                          class="font-weight-bold"
                          label=" DÉFINITION PAR DÉFAUT POUR LES CADRES"
                          inset
                        ></v-switch>
                        <v-data-table
                          dense
                          hide-default-footer
                          :items="defaultColleages[0].colleages"
                          :headers="headersCadre"
                        >
                          <template v-slot:item.designation="props">
                            <v-edit-dialog
                              :return-value.sync="props.item.designation"
                              @save="saveColleageDefault(props.item)"
                            >
                              {{ props.item.designation }}
                              <template v-slot:input>
                                <v-text-field
                                  v-model="props.item.designation"
                                  :rules="[max25chars]"
                                  label="Edit"
                                  single-line
                                  counter
                                ></v-text-field>
                              </template>
                            </v-edit-dialog>
                          </template>
                        </v-data-table>
                      </v-card-text>
                      <v-card-text>
                        <v-switch
                          class="font-weight-bold"
                          label=" DÉFINITION PAR DÉFAUT POUR LES NON CADRES"
                          inset
                        ></v-switch>

                        <v-data-table
                          dense
                          hide-default-footer
                          :items="defaultColleages[1].colleages"
                          :headers="headersCadre"
                        >
                          <template v-slot:item.designation="props">
                            <v-edit-dialog
                              :return-value.sync="props.item.designation"
                              @save="saveColleageDefault(props.item)"
                            >
                              {{ props.item.designation }}
                              <template v-slot:input>
                                <v-text-field
                                  v-model="props.item.designation"
                                  :rules="[max25chars]"
                                  label="Edit"
                                  single-line
                                  counter
                                ></v-text-field>
                              </template>
                            </v-edit-dialog>
                          </template>
                        </v-data-table>
                      </v-card-text>

                      <v-card-text>
                        <v-row>
                          <v-col cols="12" md="6">
                            <v-switch
                              class="font-weight-bold"
                              label=" DÉFINITION SPÉCIFIQUE POUR CERTAINES CCN"
                              inset
                            ></v-switch>
                          </v-col>
                          <v-col cols="12" md="6" class="text-right">
                            <v-dialog max-width="1200" v-model="dialogAddSpecificCCN">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn v-on="on" v-bind="attrs" fab icon>
                                  <i class="fa-sold fa-plus EoleBlue--text"></i>
                                </v-btn>
                              </template>

                              <v-card class="rounded-l-xl" style="box-shadow: 0 0 60px -20px #000000d9">
                                <v-card-text style="background-color: #f1f4f9">
                                  <v-row>
                                    <v-col cols="12" md="3" class="no-padding ml-n2">
                                      <v-card
                                        class="rounded-l-xl"
                                        color="#f1f4f9"
                                        style="background-color: #f1f4f9"
                                        flat
                                      >
                                        <v-card-title>
                                          <v-img
                                            :src="require('@/assets/images/logos/anavel_logo.png')"
                                            max-height="150px"
                                            max-width="120px"
                                            alt="logo"
                                            contain
                                          ></v-img>
                                        </v-card-title>
                                        <v-card-title class="text-center justify-center muller-capitalized">
                                          Mes Services Assurance</v-card-title
                                        >
                                        <v-card-subtitle class="text-center justify-center"
                                          >Un logiciel ANAVEL
                                        </v-card-subtitle>
                                        <v-card-text class="no-padding">
                                          <v-img
                                            :src="require('@/assets/images/logos/layer.png')"
                                            alt="logo"
                                            width="300px"
                                            class=""
                                          >
                                          </v-img>
                                        </v-card-text>
                                      </v-card>
                                    </v-col>
                                    <v-col cols="12" md="9" class="no-padding d-flex">
                                      <v-card class="flex-fill flex d-flex flex-column" flat>
                                        <v-card-title>
                                          <span class="text-h5">Ajout d'un nouvel article</span>
                                        </v-card-title>
                                        <v-card-text class="pa-5">
                                          <v-autocomplete
                                            :filter="customFilter"
                                            multiple
                                            class="mt-5"
                                            label="Convention collective"
                                            v-model="selectedCCN"
                                            :items="collectiveAgreements"
                                            dense
                                            item-text="designation"
                                            outlined
                                            return-object
                                          >
                                            <template v-slot:item="data">
                                              {{ data.item.designation }} - {{ data.item.idcc }}
                                            </template>
                                          </v-autocomplete>
                                        </v-card-text>
                                        <v-spacer></v-spacer>
                                        <v-card-actions>
                                          <v-spacer></v-spacer>
                                          <v-btn text @click="postColleageSpecific(selectedCCN)"> terminer </v-btn>
                                        </v-card-actions>
                                      </v-card>
                                    </v-col>
                                  </v-row>
                                </v-card-text>
                              </v-card>
                            </v-dialog>

                            <!-- <v-autocomplete
                              class="mt-5"
                              label="Convention collective"
                              v-model="selectedCCN"
                              :items="collectiveAgreements"
                              dense
                              item-text="designation"
                              outlined
                            >
                              <template v-slot:item="data"> {{ data.item.designation }} - {{ data.item.idcc }} </template>
                            </v-autocomplete> -->
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <v-text-field
                              prepend-inner-icon="mdi-magnify"
                              label="Rechercher une CCN"
                              v-model="filterCollectiveAgreement"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <div
                          v-for="(ccn, index) in filterSpecificCollectiveAgreements(specificCollectiveAgreements)"
                          v-bind:key="index"
                          class="mb-5"
                        >
                          <!-- <v-divider v-if="index > 0" class="my-5"></v-divider> -->
                          <v-card class="rounded-xl" flat outlined>
                            <v-card-title>{{ ccn.designation }} ({{ ccn.idcc }})</v-card-title>
                            <v-card-text>
                              <v-row>
                                <v-col
                                  cols="12"
                                  md="6"
                                  v-for="(colleage, index) in ccn.colleages_types"
                                  v-bind:key="index"
                                >
                                  <v-data-table
                                    dense
                                    v-if="ccn.colleages_types.length > 0"
                                    hide-default-footer
                                    :items="colleage.colleages"
                                    :headers="[{ text: colleage.designation, value: 'designation' }]"
                                  >
                                    <template v-slot:item.designation="props">
                                      <v-edit-dialog
                                        :return-value.sync="props.item.designation"
                                        @save="saveColleageSpecific(props.item)"
                                      >
                                        {{ props.item.designation }}
                                        <template v-slot:input>
                                          <v-text-field
                                            v-model="props.item.designation"
                                            :rules="[max25chars]"
                                            label="Edit"
                                            single-line
                                            counter
                                          ></v-text-field>
                                        </template>
                                      </v-edit-dialog>
                                    </template>
                                  </v-data-table>
                                </v-col>
                              </v-row>
                            </v-card-text>
                          </v-card>
                        </div>
                        {{ ccn }}
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CKEditor from "@ckeditor/ckeditor5-vue2"

import Vue from "vue"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import VuePincode from "@/layouts/components/VuePincode_pinterest.vue"
import { ref } from "@vue/composition-api"
import config from "@/views/config/config"
import { getCookie } from "@/utils/cookies"
import moment from "moment"
import { Vue2TinymceEditor } from "vue2-tinymce-editor"

Vue.use(CKEditor)

export default {
  name: "LawyerDue",
  components: {
    CKEditor,
    VuePincode,
    Vue2TinymceEditor,
  },
  computed: {
    formattedContent() {
      return this.highlightVariables(this.newArticle.content)
    },
  },
  data() {
    return {
      filterCollectiveAgreement: "",
      currentMonth: moment().format("MMMM").toUpperCase(),
      currentYear: moment().format("YYYY"),
      headersSpecificCCN: [
        {
          text: "designation",
          align: "start",
          sortable: false,
          value: "designation",
        },
      ],
      unsavedChanges: false,
      selectedCCN: null,
      specificCollectiveAgreements: [],
      defaultCollectiveAgreements: [],
      collectiveAgreementsChoose: [],
      headersCCN: [
        {
          text: "IDCC",
          align: "start",
          sortable: false,
          value: "idcc",
        },
        { text: "Désignation", align: "start", sortable: false, value: "designation" },
      ],
      dialogAddSpecificCCN: false,
      headersCadre: [
        {
          text: "Libellé",
          align: "start",
          sortable: false,
          value: "designation",
        },
      ],
      cadre: [
        {
          label: "Personnel relevant de l'article 2.1 de l'ANI du 17 Novembre 2017",
        },
        {
          label: "Personnel relevant de l'article 2.1 et 2.2 de l'ANI du 17 Novembre 2017",
        },
        {
          label: "Personnel relevant de l'article 2.1, 2.2 de l'ANI du 17 Novembre 2017 et ...",
        },
        {
          label: "Personnel cotisant à l'AGIRC tel que défini au décret du 9 Janvier 2012",
        },
      ],
      nopCadre: [
        {
          label: "Personnel ne relevant pas de l'article 2.1 de l'ANI du 17 Novembre 2017",
        },
        {
          label: "Personnel ne relevant pas de l'article 2.1 et 2.2 de l'ANI du 17 Novembre 2017",
        },
        {
          label: "Personnel ne relevant pas de l'article 2.1, 2.2 de l'ANI du 17 Novembre 2017 et ...",
        },
        {
          label: "Personnel ne cotisant pas à l'AGIRC tel que défini au décret du 9 Janvier 2012",
        },
      ],
      collectiveAgreements: [],
      tabCCN: "tab-CCN1",
      articlesSave: false,
      tabProduction: "tab-1",
      highlightedText: "",
      dialogSetProduction: [],
      dialogSetOnline: [],
      currentItemIndex: -1,
      text: "",
      menu: false,
      x: 0,
      y: 0,
      editor: ClassicEditor,
      editorData: "<p>Content of the editor.</p>",
      editorConfig: {
        // The configuration of the editor.
      },
      existantVariables: [
        {
          variable: "dueChoice.name",
          description: "Type de risque (Santé, Prévoyance, retraite)",
        },
        {
          variable: "dueColleageChoice.name",
          description: "Collège à couvrir (Cadre, Non cadre, Ensemble du personnel)",
        },
        {
          variable: "selectedInsurer",
          description: "Détermination de l'assureur",
        },
        {
          variable: "dueCotisationChoice.name",
          description: "Choix  structure de cotisation",
        },
        {
          variable: "headersOne",
          description: "Tableau répartition de cotisations",
        },
        {
          variable: "form.regimeDate2",
          description: "Date mise en place DUE",
        },
        {
          variable: "form.regimeDate",
          description: "Date modification DUE",
        },

        // {
        //   variable: "fakeData",
        //   description: "Données factices pour le tableau de répartition des cotisations",
        // },
      ],
      dialogAddArticle: false,
      newArticle: {
        title: "",
        content: "",
        comment: "",
      },
      domainTabs: {
        "tab-setup": [
          {
            title: "Santé",
            value: "sante-setup",
            icon: "fa-heart",
          },
          {
            title: "Prévoyance",
            value: "prevoyance-setup",
            icon: "fa-umbrella",
          },
          {
            title: "Retraite",
            value: "retraite-setup",
            icon: "fa-wallet",
          },
        ],
        "tab-amendment": [
          {
            title: "Santé",
            value: "sante-amendment",
            icon: "fa-heart",
          },
          {
            title: "Prévoyance",
            value: "prevoyance-amendment",
            icon: "fa-umbrella",
          },
          {
            title: "Retraite",
            value: "retraite-amendment",
            icon: "fa-wallet",
          },
        ],
      },
      tabMain: "tab-setup",
      selectedDomainSetup: "sante-setup",
      selectedDomainAmendment: "sante-amendment",
      articles: {},
      defaultColleages: [],
    }
  },
  mounted() {
    document.addEventListener("keydown", this.handleKeydown)

    window.addEventListener("beforeunload", function (e) {
      // Condition pour déterminer si des modifications non enregistrées sont présentes
      if (unsavedChangesPresent()) {
        alert("proute")

        e.preventDefault()

        // Message standard pour les navigateurs qui supportent cette fonctionnalité
        // (les navigateurs ne montrent pas ce message exact à l'utilisateur, mais utilisent leur propre version)
        e.returnValue = "Vous avez des modifications non enregistrées. Êtes-vous sûr de vouloir quitter ?"
        return e.returnValue
      }
    })
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.handleKeydown)
  },
  async created() {
    this.fetchArticles()
    this.fetchCollectiveAgreements()
    console.log(this.articles)

    this.fetchSpecificCollectivesAgreements()
    this.getDefaultColleages()
  },
  methods: {
    async deleteSpecificCCN(ccn) {
      try {
        const response = await fetch(`${config.apiUri}/collective-agreements/colleages/specific/${ccn.id}`, {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("jwt")}`,
          },
        })

        if (!response.ok) {
          throw new Error("Failed to delete specific colleage")
        }

        this.fetchSpecificCollectivesAgreements()
        this.$toast.success("Collège spécifique supprimé avec succès", { position: "bottom-right", timeout: 3000 })
      } catch (error) {
        console.error("Error deleting specific colleage:", error)
        this.$toast.error("Erreur lors de la suppression du collège spécifique", {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },
    filterSpecificCollectiveAgreements(ccn) {
      let search = this.filterCollectiveAgreement.toLowerCase()
      return ccn.filter(item => {
        return item.designation.toLowerCase().includes(search) || item.idcc.toString().includes(search)
      })
    },
    deleteArticle(article) {
      this.$toast.warning("Fonction désactivée pour le moment", { position: "bottom-right", timeout: 3000 })
    },
    customFilter(item, queryText, itemText) {
      console.log(item)
      const idccString = item.idcc.toString()
      const text = item.designation.toLowerCase() + " " + idccString
      const searchText = queryText.toLowerCase()
      return text.includes(searchText)
    },
    async postColleageSpecific(idCCN) {
      console.log(idCCN)
      try {
        const response = await fetch(`${config.apiUri}/collective-agreements/colleages/specific`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",

            Authorization: `Bearer ${getCookie("jwt")}`,
          },
          body: JSON.stringify({
            idCCN: idCCN,
          }),
        })

        if (!response.ok) {
          throw new Error("Failed to save specific colleage")
        }

        this.selectedCCN = null
        this.fetchSpecificCollectivesAgreements()
        this.dialogAddSpecificCCN = false

        this.$toast.success("Collège spécifique enregistré avec succès", { position: "bottom-right", timeout: 3000 })
      } catch (error) {
        console.error("Error saving specific colleage:", error)
        this.$toast.error("Erreur lors de l'enregistrement du collège spécifique", {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },
    async saveColleageSpecific(specificColleage) {
      try {
        const response = await fetch(`${config.apiUri}/due/colleages/specific/${specificColleage.id}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("jwt")}`,
          },
          body: JSON.stringify({
            designation: specificColleage.designation,
          }),
        })

        if (!response.ok) {
          throw new Error("Failed to save specific colleage")
        }

        this.$toast.success("Collège spécifique enregistré avec succès", { position: "bottom-right", timeout: 3000 })
      } catch (error) {
        console.error("Error saving specific colleage:", error)
        this.$toast.error("Erreur lors de l'enregistrement du collège spécifique", {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },
    async saveColleageDefault(defaultColleage) {
      try {
        const response = await fetch(`${config.apiUri}/due/colleages/default/${defaultColleage.id}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("jwt")}`,
          },
          body: JSON.stringify({
            designation: defaultColleage.designation,
          }),
        })

        if (!response.ok) {
          throw new Error("Failed to save default colleage")
        }

        this.$toast.success("Collège par défaut enregistré avec succès", { position: "bottom-right", timeout: 3000 })
      } catch (error) {
        console.error("Error saving default colleage:", error)
        this.$toast.error("Erreur lors de l'enregistrement du collège par défaut", {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },
    async getDefaultColleages() {
      try {
        const response = await fetch(`${config.apiUri}/due/colleages/default`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("jwt")}`,
          },
        })

        const data = await response.json()
        this.defaultColleages = data
      } catch (error) {
        console.log(error)
      }
    },
    removeSpecificCollectiveAgreementsfromList(id) {
      this.collectiveAgreements.forEach((element, index) => {
        if (element.id === id) {
          this.collectiveAgreements.splice(index, 1)
        }
      })
    },
    async fetchSpecificCollectivesAgreements() {
      try {
        const response = await fetch(`${config.apiUri}/collective-agreements/colleages/specific`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("jwt")}`,
          },
        })

        const data = await response.json()
        this.specificCollectiveAgreements = data

        this.specificCollectiveAgreements.forEach(element => {
          this.removeSpecificCollectiveAgreementsfromList(element.id)
        })
      } catch (error) {
        console.log(error)
      }
    },
    async saveArticleById(article) {
      try {
        const response = await fetch(`${config.apiUri}/due/articles/${article.id}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("jwt")}`,
          },
          body: JSON.stringify({
            article,
          }),
        })

        if (!response.ok) {
          throw new Error("Failed to save article")
        }

        this.$toast.success("Article enregistré avec succès", { position: "bottom-right", timeout: 3000 })
      } catch (error) {
        console.error("Error saving article:", error)
        this.$toast.error("Erreur lors de l'enregistrement de l'article", { position: "bottom-right", timeout: 3000 })
      }
    },
    saveArticle(article) {
      article.unsavedChanges = true

      this.saveArticleById(article)
    },
    unsavedChangesPresent() {
      this.articles.forEach(article => {
        if (article.unsavedChanges) {
          return true
        }
      })
    },
    onContentChange(article) {
      article.unsavedChanges = false
    },
    saveChanges(article) {
      // Implémentez la logique de sauvegarde ici
      article.unsavedChanges = false
    },
    addSpecificCCN(selectedCCN) {
      this.dialogAddSpecificCCN = false
    },
    async fetchCollectiveAgreements() {
      try {
        const response = await fetch(`${config.apiUri}/collective-agreements`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("jwt")}`,
          },
        })

        const data = await response.json()
        this.collectiveAgreements = data

        console.log(this.collectiveAgreements)
      } catch (error) {
        console.log(error)
      }
    },
    initializeUnsavedChanges(data) {
      // Parcourir chaque catégorie (sante, prevoyance, etc.)
      Object.keys(data).forEach(category => {
        // Vérifier si la valeur sous la catégorie est bien un tableau
        if (data[category]) {
          console.log("proute", data[category])

          Object.keys(data[category]).forEach(articleIndex => {
            // Vérifier si la valeur sous l'index de l'article est bien un tableau
            if (data[category][articleIndex]) {
              // Ajouter une propriété 'unsavedChanges' à chaque article
              data[category][articleIndex].forEach(article => {
                article.unsavedChanges = true
              })
            }
          })
        }
      })

      console.log("Data after adding unsavedChanges:", data)
      return data
    },
    async fetchArticles() {
      try {
        const response = await fetch(`${config.apiUri}/due/articles`, {
          headers: {
            Authorization: `Bearer ${getCookie("jwt")}`, // Assurez-vous que l'URL correspond à votre configuration de route
          },
        }) // Assurez-vous que l'URL correspond à votre configuration de route
        if (!response.ok) {
          throw new Error("Failed to fetch articles")
        }
        const articles = await response.json()
        // Initialisez 'unsavedChanges' pour chaque article
        const articlesWithChanges = this.initializeUnsavedChanges(articles)

        this.articles = articlesWithChanges

        console.log(this.articles)
      } catch (error) {
        console.error("Error fetching articles:", error)
        this.$toast.error("Erreur lors de la récupération des articles", { position: "bottom-right", timeout: 3000 })
      }
    },

    handleKeydown(event) {
      // Vérifie si 'Ctrl' et 'S' sont pressés ensemble
      if (event.ctrlKey && event.keyCode === 83) {
        event.preventDefault() // Empêche le comportement par défaut de Ctrl+S (généralement enregistrer la page)
        this.saveArticles() // Appelle la méthode saveArticles
      }
    },
    async saveArticles() {
      this.articlesSave = true // Début de l'opération de sauvegarde

      try {
        const response = await fetch(`${config.apiUri}/due/articles/save`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getCookie("jwt")}`, // Assurez-vous d'implémenter l'authentification selon vos besoins
          },
          body: JSON.stringify({
            articles: this.articles,
          }),
        })

        if (!response.ok) {
          throw new Error("Failed to save articles")
        }

        this.$toast.success("Articles sauvegardés avec succès", {
          position: "bottom-right",
          timeout: 3000,
        })
      } catch (error) {
        console.error("Error saving articles:", error)
        this.$toast.error(`Erreur lors de la sauvegarde des articles: ${error.message}`, {
          position: "bottom-right",
          timeout: 3000,
        })
      } finally {
        this.articlesSave = false // Fin de l'opération de sauvegarde
      }
    },
    async checkPincodeOnline(pincode, article) {
      const dialogKey = article.id

      try {
        const response = await fetch(`${config.apiUri}/accounts/${this.$store.state.user.id}/verify-pin`, {
          method: "POST",
          body: JSON.stringify({ pin: pincode }),
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${getCookie("jwt")}`,
          },
        })

        if (response.ok) {
          this.$toast.success("Code PIN correct !", {
            position: "bottom-right",
            timeout: 3000,
          })

          article.items[0].online = true

          this.dialogSetOnline[dialogKey] = false

          this.$refs.pincodeInput[0].resetPincode()

          this.saveArticles()
        } else {
          // Gestion des réponses non-OK avec extraction du message d'erreur
          const errorData = await response.json()
          this.$toast.error(`Code PIN incorrect : ${errorData.message}`, {
            position: "bottom-right",
            timeout: 3000,
          })
          this.$nextTick(() => {
            if (this.$refs.pincodeInput && this.$refs.pincodeInput[0]) {
              this.$refs.pincodeInput[0].triggerMiss() // S'assurer que triggerMiss est appelé
            }
          })
        }
      } catch (error) {
        // Gestion des erreurs réseau ou autres erreurs globales
        this.$toast.error(`Connexion impossible. ${error.message}\nVeuillez réessayer.`, {
          position: "bottom-right",
          timeout: 3000,
        })
        this.$nextTick(() => {
          if (this.$refs.pincodeInput && this.$refs.pincodeInput[0]) {
            this.$refs.pincodeInput[0].triggerMiss() // S'assurer que triggerMiss est appelé en cas d'erreur réseau
          }
        })
      }
    },
    getDialogKey(article) {
      return article.id
    },
    generateUniqueId() {
      return Date.now().toString(36) + Math.random().toString(36).substr(2, 5)
    },
    addArticle(mainTab, domain) {
      const newId = this.generateUniqueId()

      const domainName = domain.split("-")[0]
      const newArticle = {
        id: newId,
        title: this.newArticle.title,
        items: [
          {
            title: "En production",
            content: "",
            tab: "tab-2",
            comment: "",
            online: false,
          },
          {
            title: "En préproduction",
            content: this.newArticle.content,
            tab: "tab-3",
            comment: this.newArticle.comment,
          },
        ],
        tab: "tab-3",
        unsavedChanges: true,
      }
      this.articles[mainTab][domainName].push(newArticle)

      this.dialogSetProduction[newId] = false
      this.newArticle = { title: "", content: "", comment: "" }

      this.saveArticles()
      this.dialogAddArticle = false
    },
    openArticleDialog(articleId) {
      this.dialogSetProduction[articleId] = true
    },
    closeArticleDialog(articleId) {
      this.dialogSetProduction[articleId] = false
    },
    async checkPincode(pincode, article) {
      const dialogKey = article.id

      try {
        const response = await fetch(`${config.apiUri}/accounts/${this.$store.state.user.id}/verify-pin`, {
          method: "POST",
          body: JSON.stringify({ pin: pincode }),
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${getCookie("jwt")}`,
          },
        })

        if (response.ok) {
          this.$toast.success("Code PIN correct !", {
            position: "bottom-right",
            timeout: 3000,
          })

          //if is setup article
          if (this.tabMain === "tab-setup") {
            this.promoteToProductionSetup(article) // Supposition que cette fonction met à jour l'article pour la production
          } else {
            this.promoteToProductionAmendment(article) // Supposition que cette fonction met à jour l'article pour la production
          }

          article.tab = "tab-2"
          this.dialogSetProduction[dialogKey] = false
          this.tabProduction = "tab-1"

          this.$refs.pincodeInput[0].resetPincode() // Réinitialiser le champ de code PIN
        } else {
          // Gestion des réponses non-OK avec extraction du message d'erreur
          const errorData = await response.json()
          this.$toast.error(`Code PIN incorrect : ${errorData.message}`, {
            position: "bottom-right",
            timeout: 3000,
          })
          this.$nextTick(() => {
            if (this.$refs.pincodeInput && this.$refs.pincodeInput[0]) {
              this.$refs.pincodeInput[0].triggerMiss() // S'assurer que triggerMiss est appelé
            }
          })
        }
      } catch (error) {
        // Gestion des erreurs réseau ou autres erreurs globales
        this.$toast.error(`Connexion impossible. ${error.message}\nVeuillez réessayer.`, {
          position: "bottom-right",
          timeout: 3000,
        })
        this.$nextTick(() => {
          if (this.$refs.pincodeInput && this.$refs.pincodeInput[0]) {
            this.$refs.pincodeInput[0].triggerMiss() // S'assurer que triggerMiss est appelé en cas d'erreur réseau
          }
        })
      }
    },

    getNumberOfThisVariableAreInTextArticle(variable) {
      const article = this.articles.setup.sante.find(a => a.id === this.currentArticleId)
      if (article) {
        const item = article.items[this.currentItemIndex]
        return (item.content.match(new RegExp(`{{${variable}}}`, "g")) || []).length
      }

      return 0
    },
    getNumberOfThisVariableAreInText(variable, text) {
      // Compter le nombre de fois que la variable apparaît dans le texte
      return (this.newArticle.content.match(new RegExp(`{{${variable}}}`, "g")) || []).length
    },

    insertVariableInNewArticle(variable) {
      const textarea = this.$refs.textareaContent.$refs.input // Accéder à l'élément de texte
      const startPos = textarea.selectionStart
      const endPos = textarea.selectionEnd
      const textBefore = this.newArticle.content.substring(0, startPos)
      const textAfter = this.newArticle.content.substring(endPos)
      this.newArticle.content = textBefore + `{{${variable}}}` + textAfter

      // Rétablir le focus et positionner le curseur juste après la variable insérée
      this.$nextTick(() => {
        textarea.focus()
        const newPos = startPos + `{{${variable}}}`.length
        textarea.setSelectionRange(newPos, newPos)
      })
    },
    archiveArticle(article) {
      // Trouver l'article concerné dans la structure des données
      const domainName = this.selectedDomainAmendment.split("-")[0] // Extrait 'sante' de 'sante-setup'
      const articles = this.articles.amendment[domainName] // Supposition que l'article vient de 'setup' ou 'amendment'
      const articleIndex = articles.findIndex(a => a.id === article.id)

      if (articleIndex !== -1) {
        const productionItem = articles[articleIndex].items.find(item => item.title === "En production")
        if (productionItem) {
          // Créer un nouvel élément d'archive avec le contenu de production
          const today = new Date()
          const dateString = today.toLocaleDateString("fr-FR") // Format 'jj/mm/aaaa' pour la locale française
          const archiveItem = {
            title: dateString, // Titre avec la date du jour
            content: productionItem.content, // Contenu copié de l'élément de production
            comment: productionItem.comment, // Commentaire copié de l'élément de production
            tab: `tab-archive-${dateString.replace(/\//g, "-")}`, // Un identifiant unique pour le nouvel onglet, avec la date
            online: false,
          }

          // Ajouter l'élément d'archive au tableau des items
          articles[articleIndex].items.push(archiveItem)

          this.$toast.success(`Article "${articles[articleIndex].title}" archivé avec succès le ${dateString}`, {
            position: "bottom-right",
            timeout: 3000,
          })

          // Supprimer l'élément de production

          articles[articleIndex].items = articles[articleIndex].items.filter(item => item.title !== "En production")

          this.saveArticles()
        } else {
          console.error("Impossible de trouver l'élément de production pour l'archivage")
          this.$toast.error("Échec de l'archivage: Article en production introuvable.", {
            position: "bottom-right",
            timeout: 3000,
          })
        }
      } else {
        console.error("Article non trouvé pour l'archivage")
        this.$toast.error("Échec de l'archivage: Article non trouvé.", {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },

    archiveArticleSetup(article) {
      const domainName = this.selectedDomainSetup.split("-")[0] // Extrait 'sante' de 'sante-setup'
      const articles = this.articles.setup[domainName] // Supposons que l'article vient de 'setup' ou 'amendment'
      const articleIndex = articles.findIndex(a => a.id === article.id)

      if (articleIndex !== -1) {
        const productionItem = articles[articleIndex].items.find(item => item.title === "En production")
        if (productionItem) {
          // Créer un nouvel élément d'archive avec le contenu de production
          const today = new Date()
          const dateString = today.toLocaleDateString("fr-FR") // Format 'jj/mm/aaaa' pour la locale française
          const archiveItem = {
            title: dateString, // Titre avec la date du jour
            content: productionItem.content, // Contenu copié de l'élément de production
            comment: productionItem.comment, // Commentaire copié de l'élément de production
            tab: `tab-archive-${dateString.replace(/\//g, "-")}`, // Un identifiant unique pour le nouvel onglet, avec la date
            online: false,
          }

          // Ajouter l'élément d'archive au tableau des items
          articles[articleIndex].items.push(archiveItem)

          this.$toast.success(`Article "${articles[articleIndex].title}" archivé avec succès le ${dateString}`, {
            position: "bottom-right",
            timeout: 3000,
          })

          // Supprimer le contenu de production et le commentaire
          productionItem.content = ""
          productionItem.comment = ""

          this.saveArticles()
        } else {
          console.error("Impossible de trouver l'élément de production pour l'archivage")
          this.$toast.error("Échec de l'archivage: Article en production introuvable.", {
            position: "bottom-right",
            timeout: 3000,
          })
        }
      } else {
        console.error("Article non trouvé pour l'archivage")
        this.$toast.error("Échec de l'archivage: Article non trouvé.", {
          position: "bottom-right",
          timeout: 3000,
        })
      }
    },
    promoteToProductionAmendment(article) {
      // Trouver l'article concerné dans la structure des données
      const domainName = this.selectedDomainAmendment.split("-")[0] // Extrait 'sante' de 'sante-setup'
      const articles = this.articles.amendment[domainName] // Supposition que l'article vient de 'setup'
      const articleIndex = articles.findIndex(a => a.id === article.id)

      if (articleIndex !== -1) {
        const productionItem = articles[articleIndex].items.find(item => item.title === "En production")
        const preproductionItem = articles[articleIndex].items.find(item => item.title === "En préproduction")

        if (productionItem && preproductionItem) {
          // Transférer le contenu de préproduction à production
          productionItem.online = false
          productionItem.content = preproductionItem.content
          productionItem.comment = preproductionItem.comment

          // Réinitialiser le contenu de préproduction
          preproductionItem.content = ""
          preproductionItem.comment = ""
        } else {
          console.error("Impossible de trouver les éléments de préproduction ou de production")
        }
      } else {
        console.error("Article non trouvé pour la promotion")
      }
    },
    promoteToProductionSetup(article) {
      // Trouver l'article concerné dans la structure des données
      const domainName = this.selectedDomainSetup.split("-")[0] // Extrait 'sante' de 'sante-setup'
      const articles = this.articles.setup[domainName] // Supposition que l'article vient de 'setup'
      const articleIndex = articles.findIndex(a => a.id === article.id)

      if (articleIndex !== -1) {
        const productionItem = articles[articleIndex].items.find(item => item.title === "En production")
        const preproductionItem = articles[articleIndex].items.find(item => item.title === "En préproduction")

        if (productionItem && preproductionItem) {
          // Transférer le contenu de préproduction à production
          productionItem.online = false
          productionItem.content = preproductionItem.content
          productionItem.comment = preproductionItem.comment

          // Réinitialiser le contenu de préproduction
          preproductionItem.content = ""
          preproductionItem.comment = ""

          this.saveArticleById(article)
        } else {
          console.error("Impossible de trouver les éléments de préproduction ou de production")
        }
      } else {
        console.error("Article non trouvé pour la promotion")
      }
    },
    openMenu(event, mainTab, domain, articleId, itemIndex) {
      this.currentMainTab = mainTab
      this.currentDomain = domain
      this.currentArticleId = articleId // Utilisez currentArticleId pour stocker l'ID actuel de l'article
      this.currentItemIndex = itemIndex
      this.menu = true
      this.x = event.clientX
      this.y = event.clientY
    },
    insertVariable(variable, index) {
      const domainName = this.currentDomain.split("-")[0] // Extrait 'sante' de 'sante-setup'
      const articles = this.articles[this.currentMainTab][domainName]
      const article = articles.find(a => a.id === this.currentArticleId)

      if (article) {
        const item = article.items[this.currentItemIndex]

        if (item.title === "En préproduction") {
          if (item) {
            // Récupération de l'élément de texte et des positions de sélection
            const textarea = this.$refs[`textarea-${this.currentArticleId}-${this.currentItemIndex}`][0].$refs.input
            const start = textarea.selectionStart
            const end = textarea.selectionEnd

            // Insertion de la variable dans le contenu surligné
            const textBefore = item.content.substring(0, start)
            const textAfter = item.content.substring(end)
            item.content = textBefore + `{{${variable}}}` + textAfter

            // Mise à jour forcée pour refléter le changement dans Vue
            // Cela peut être nécessaire si vous manipulez des tableaux ou des objets
            // et que Vue ne détecte pas automatiquement le changement
            this.$set(article.items, this.currentItemIndex, { ...item })

            // Fermeture du menu contextuel
            this.menu = false

            // Optionnel: rétablir le focus et positionner le curseur après la variable insérée
            this.$nextTick(() => {
              textarea.focus()
              const cursorPosition = textBefore.length + `{{${variable}}}`.length
              textarea.setSelectionRange(cursorPosition, cursorPosition)
            })
          }
        }
      }
    },
    openDialogAddArticle() {
      this.dialogAddArticle = true
      this.newArticle = {
        title: "",
        content: "",
        comment: "",
      }
    },
    changeTab(domain) {
      // Ici, mettez à jour le modèle utilisé par `v-tabs-items`.
      // Cette mise à jour dépend de la façon dont vous avez structuré votre composant.
      // Par exemple, si `selectedDomainSetup` est lié à `v-tabs-items` pour afficher le contenu :
      this.selectedDomainSetup = "prote"
    },
    articlesForDomain(mainTab, domain) {
      // Extraire le nom du domaine depuis l'identifiant complet de l'onglet
      const domainName = domain.split("-")[0]
      return this.articles[mainTab][domainName]
    },
    convertDomainKeyToName(domainKey) {
      // Conversion d'un identifiant de domaine en nom lisible, si nécessaire
      const domainNames = {
        "sante-setup": "Santé",
        "prevoyance-setup": "Prévoyance",
        "retraite-setup": "Retraite",
        // Ajoutez d'autres correspondances selon vos besoins
      }
      return domainNames[domainKey] || domainKey
    },
  },
  //   addArticle(mainTab, domainValue) {
  //     // Trouver le domaine correspondant dans la structure des données
  //     const domainName = domainValue.split("-")[0] // Extrait 'sante' de 'sante-setup'

  //     // Créer un nouvel article

  //     // Ajouter le nouvel article au tableau correspondant
  //     this.articles[mainTab][domainName].push({
  //       id: this.articles[mainTab][domainName].length + 1,
  //       title: this.newArticle.title,
  //       items: [
  //         {
  //           title: "En production",
  //           content: "",
  //           tab: "tab-2",
  //           comment: "",
  //         },
  //         {
  //           title: "En préproduction",
  //           content: this.newArticle.content,
  //           tab: "tab-3",
  //           comment: this.newArticle.comment,
  //         },
  //       ],
  //     })

  //     // Réinitialiser le formulaire
  //     this.newArticle = {
  //       title: "",
  //       content: "",
  //       comment: "",
  //     }

  //     // Fermer la boîte de dialogue
  //     this.dialogAddArticle = false
  //   },
  // },
  // Consider adding components for article management (e.g., articles-component)
}
</script>

<style>
.editable-container {
  position: relative;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.editable-textarea {
  padding: 10px;
  min-height: 100px;
  overflow: auto;
}

.editable-textarea .v-textarea--outlined {
  border: none;
  box-shadow: none;
}

.highlighted {
  background-color: yellow;
}
</style>
