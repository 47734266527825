<template>
  <div>
    <v-row class="px-5">
      <v-col cols="12" md="12">
        <div class="vue-pincode" :class="pincodeSuccess ? 'vue-pincode--success' : ''">
          <div class="vue-pincode__fields" :class="pincodeError ? 'vue-pincode__fields--miss' : ''">
            <span :class="pincode.length >= 1 ? 'active' : ''" />
            <span :class="pincode.length >= 2 ? 'active' : ''" />
            <span :class="pincode.length >= 3 ? 'active' : ''" />
            <span :class="pincode.length >= 4 ? 'active' : ''" />
            <span :class="pincode.length >= 5 ? 'active' : ''" />
            <span :class="pincode.length >= 6 ? 'active' : ''" />
          </div>
        </div>
      </v-col>
    </v-row>
    <!-- <v-card-subtitle class="subtitle-2">Votre session expirera dans : 4 heures</v-card-subtitle> -->
    <v-card-text class="justify-center mt-n8">
      <v-row class="justify-center text-center no-padding">
        <!-- <v-col v-for="(number, idx) in arrayNumber" cols="12" sm="4" :key="idx" >
                <v-btn
                  :retain-focus-on-click="false"
                  :ripple="false"
                  fab
                  text
                  class="pincodeButton"
                  color="white"
                  :key="idx"
                  @click="clickPinButton(number)"
                  :disabled="buttonDisabled"
                >
                  {{ number }}
                </v-btn>
              </v-col> -->
        <v-col v-for="(number, idx) in arrayNumber" cols="12" sm="4" :key="idx" class="col-pin">
          <v-btn
            :id="`pincode-${number}`"
            min-width="100%"
            min-height="50px"
            outlined
            :retain-focus-on-click="false"
            class="pincodeButton"
            color="EoleBlue"
            :key="idx"
            @click="clickPinButton(number)"
            :disabled="buttonDisabled"
          >
            {{ number }}
          </v-btn>
        </v-col>
        <v-col cols="12" sm="12">
          <v-btn
            min-height="50px"
            small
            block
            :retain-focus-on-click="false"
            :ripple="false"
            outlined
            class="pincodeButton"
            color="EoleError"
            @click="removeOnePincode"
            :disabled="buttonDisabled"
          >
            <v-icon>mdi-backspace</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <!-- <v-row v-else class="justify-center">
            <v-col v-for="(number, idx) in arrayNumber" cols="4" md="6" :key="idx">
              <v-btn
                :retain-focus-on-click="false"
                :ripple="false"
                fab
                text
                class="pincodeButton EoleYellow--text"
                color=""
                :key="idx"
                @click="clickPinButton(number)"
                :disabled="buttonDisabled"
              >
                {{ number }}
              </v-btn>
            </v-col>
          </v-row> -->
    </v-card-text>
  </div>
  <!-- <div class="EoleBlue">
      <v-card class="pt-10 EoleBlue mx-15" flat>
        <v-card-text> </v-card-text>
      </v-card>
    </div> -->
</template>

<script>
// Import component
import Loading from "vue-loading-overlay"
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css"

export default {
  name: "VuePincode",
  components: {
    Loading,
  },
  data() {
    return {
      waitTimeoutPincode: false,
      pincodeDisplay: "",
      test: "12",
      arrayNumber: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
      pincode: "",
      pincodeError: false,
      pincodeSuccess: false,
    }
  },
  mounted() {
    this.arrayNumber.sort(() => Math.random() - 0.5)

    // take one number random in this array and put it in secretNumber and delete it from arrayNumber
    // this.secretNumber = this.arrayNumber[Math.floor(Math.random() * this.arrayNumber.length)]
    // this.arrayNumber.splice(this.arrayNumber.indexOf(this.secretNumber), 1)
  },

  computed: {
    pincodeLength() {
      return this.pincode.length
    },
    buttonDisabled() {
      return this.pincodeError || this.pincodeSuccess
    },
  },
  watch: {
    pincode() {
      if (this.pincodeLength === 6) {
        this.$emit("pincode", this.pincode)
      }
    },
  },
  destroyed() {
    this.resetPincode()
  },
  methods: {
    getPincode() {
      return this.pincode
    },
    clickPinButton(pressedNumber) {
      pressedNumber = String(pressedNumber)
      if (this.pincodeLength < 6) {
        this.pincode += pressedNumber
      }
    },
    resetPincode() {
      this.pincode = ""
      this.pincodeError = false
      this.pincodeSuccess = false
    },
    removeOnePincode() {
      this.pincode = this.pincode.slice(0, -1)
    },
    triggerMiss() {
      this.pincodeError = true
      setTimeout(() => this.resetPincode(), 1200)
    },

    triggerSuccess() {
      this.waitTimeoutPincode = true
      this.pincodeSuccess = true
      setTimeout(() => this.resetPincode(), 2500)
    },
  },
}
</script>

<style scoped lang="scss">
.row-pin {
  text-align: center;
  width: 80%;
}
.col-pin {
  max-width: 20%;
}
.back {
  background-color: #2f2e41;
}

.gradient {
  //   background: rgb(0,31,71);
  // background: linear-gradient(0deg, rgba(0,31,71,1) 20%, rgba(230,230,230,1) 100%);
  // background: rgb(230,230,230);
  // background: radial-gradient(circle, rgba(230,230,230,1) 0%, rgba(0,31,71,1) 100%);
}

.v-btn--active.no-active::before {
  opacity: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

@media (min-width: 1600px) {
  .pincodeButton {
    font-size: 1rem !important;
    width: 10px !important;
    height: 10px !important;
  }
}

.flex.lg5-custom {
  width: 20%;
  max-width: 20%;
  flex-basis: 20%;
}

.vue-pincode {
  padding: 15px;

  &__fields {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 200px;
    padding: 0 20px;
    margin: 20px auto 50px;

    &--miss {
      animation: miss 0.8s ease-out 1;

      span {
        box-shadow: inset 0 0 0 7px #c1272d !important;
      }
    }

    span {
      height: 14px;
      width: 14px;
      box-shadow: inset 0 0 0 2px #36485e;
      background-color: transparent;
      border-radius: 100%;
      position: relative;
      display: inline-block;
      text-align: center;
      transition: box-shadow 0.2s linear;

      &.active {
        box-shadow: inset 0 0 0 7px #36485e;
      }
    }
  }

  &__numbers {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: center;
    align-items: center;
    row-gap: 20px;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      color: #36485e;
      background: #e8e8e8;
      user-select: none;
      font-size: 10px;
      outline: none;
      cursor: pointer;
      border: 1px solid rgba(255, 255, 255, 0.5);
      transition: all 0.2s linear;
      box-shadow: 7px 7px 15px #36485e36, -4px -4px 13px #ffffff, inset 4px 4px 8px rgba(209, 217, 230, 0.35),
        inset -8px -8px 8px rgba(255, 255, 255, 0.3);

      &:hover {
        box-shadow: 4px 2px 18px #36485e36, -4px -4px 13px #ffffff, inset 6px 6px 16px rgba(209, 217, 230, 0.8),
          inset -8px -8px 8px rgba(255, 255, 255, 0.2);
      }

      &:active {
        box-shadow: 4px 2px 18px #36485e36, -4px -4px 13px #ffffff, inset 6px 6px 16px rgba(209, 217, 230, 0.8),
          inset -8px -8px 8px rgba(255, 255, 255, 0.2);
        transform: translateY(2px);
      }

      span {
        opacity: 1;
        transition: all 0.2s linear;
      }
    }
  }

  &--success {
    .vue-pincode__numbers {
      button {
        box-shadow: 0 0 0 #bbcfda, 0 0 0 #ffffff, inset 0 0 0 rgba(209, 217, 230, 0.35),
          inset 0 0 0 rgba(255, 255, 255, 0.3);
        transform: translateY(2px);
        color: #36485e52;

        span {
          opacity: 0.4;
        }
      }
    }

    .vue-pincode__fields {
      span {
        box-shadow: inset 0 0 0 7px #41b883 !important;
      }
    }

    .vue-pincode__undo {
      svg {
        fill: #36485e52;
      }
    }
  }

  &__undo {
    span {
      transform: translateY(3px);
    }

    svg {
      width: 32px;
      height: 32px;
      transform: rotate(45deg);
      transition: transform 0.3s cubic-bezier(0.85, 0, 0.15, 1);
      fill: #36485e;
    }

    &:hover {
      svg {
        transform: rotate(-135deg);
      }
    }
  }
}

@keyframes miss {
  0% {
    transform: translate(0, 0);
  }

  10% {
    transform: translate(-25px, 0);
  }

  20% {
    transform: translate(25px, 0);
  }

  30% {
    transform: translate(-20px, 0);
  }

  40% {
    transform: translate(20px, 0);
  }

  50% {
    transform: translate(-10px, 0);
  }

  60% {
    transform: translate(10px, 0);
  }

  70% {
    transform: translate(-5px, 0);
  }

  80% {
    transform: translate(5px, 0);
  }

  100% {
    transform: translate(0, 0);
  }
}
</style>
